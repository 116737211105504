@import "styles/variables";


:global {
  .ant-modal-root {
    z-index: 1112;
  }

  .ant-image-preview-mask,
  .ant-modal-mask {
    background-color: rgba($black, 0.65);
  }
  .ant-modal-body {
    padding: 48px 42px;

    @media screen and (max-width: $breakpoint-lg) {
      padding: 24px 15px;
    }
  }
  .close-icon-wrapper {
    color: $black;
    & > *:last-child {
      display: none;
    }

    @media screen and (max-width: $breakpoint-lg) {
      & > *:first-child {
        display: none;
      }
      & > *:last-child {
        display: inline-block;
        margin-left: -10px;
        font-size: 13px;
      }
    }
  }
  .ant-image-preview-wrap,
  .ant-modal-wrap {
    height: 100%;
    overflow: hidden;
  }
  .ant-modal {
    @media screen and (max-height: 500px) {
      top: 0;
    }
  }
}

@keyframes dsModalIn {
  0% {
    transform: translate(100%, 0%);
    opacity: 0;
  }
  100% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}

@keyframes dsModalOut {
  0% {
    transform: translate(100%, 0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%, 0%);
    opacity: 0;
  }
}

.fromSide {
  &:global(.ant-modal) {
    left: 0;
    margin: 0 0 0 auto;
    top: 0;
    height: 100%;
    background: $white;
    padding: 0;
    animation-duration: 0s;
  }
  :global(.ant-modal-content) {
    min-height: 100%;
  }
  &:global(.zoom-leave) {
    &:global(.zoom-leave-active) {
      animation-name: dsModalOut;
      animation-play-state: running;
      pointer-events: none;
    }
  }
  &:global(.zoom-enter),
  &:global(.zoom-appear) {
    animation-duration: 0.3s;
    transform: transform(100%, 0%);
    &:global(.zoom-enter-active),
    &:global(.zoom-appear-active) {
      animation-name: dsModalIn;
      animation-play-state: running;
    }
  }
}
