@import "styles/variables";
@import "styles/mixins";

.footer {
  padding: 38px 0 0;
  background-color: $white;
  overflow: hidden;
  border-top: 1px solid $gray-550;

  @media print {
    display: none;
  }

  @media screen and (max-width: $breakpoint-lg) {
    padding: 30px 0;
  }
}

.quoteFooter {
  composes: footer;
  padding: 32px 0 40px;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 24px 0;
  }
}

.brandImage {
  max-width: 150px;
  opacity: 0.5;
  margin-bottom: 30px;
}

.logoImage {
  max-width: 165px;
  width: 100%;
  opacity: 0.75;

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 150px;
  }
}

.quoteLogoImage {
  composes: logoImage;
  margin-bottom: 35px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 24px;
  }
}

.footerLink {
  margin-bottom: 4px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 0;
  }
}

a.footerLink {
  &:hover {
    color: $black;
    text-decoration: underline;
  }
  @media screen and (max-width: $breakpoint-md) {
    min-width: 48px;
    margin-top: 8px;
  }
}

a.quoteContact,
p.quoteContact {
  color: $black;
  margin-top: -6px;
}

a.quoteContact {
  margin: 4px 0;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
}

.mediaLink {
  &.primary {
    svg {
      color: $teal;
    }
  }
  svg {
    transform: scale(1.25);
    transition: transform 150ms ease;
    @media screen and (max-width: $breakpoint-md) {
      height: 32px;
      width: 32px;
    }
    color: $black;
    &:hover {
      transform: scale(1.5);
    }
  }
  :global {
    @media screen and (max-width: $breakpoint-md) {
      svg.fa-tiktok-square {
          width: 32px !important;
      }
    }
  }
}

.mediaLinkRow {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  padding: 7px 0 0 7.5px;
  gap: 15px;
  & > * {
    margin-bottom: 5px;
  }
  @media screen and (max-width: $breakpoint-md) {
    gap: 8px
  }
}

.copyright {
  color: $black;
  font-weight: 400;
  margin: 0;
  line-height: 27px;
  &:hover {
    color: $black;
    text-decoration: none;
    transform: scale(1);
  }
}

.bottomLink {
  &:link,
  &:visited {
    &:hover {
      color: $black;
      text-decoration: underline;
    }
  }
}

a.bottomLink,
p.bottomLink {
  min-width: 48px;
  margin: 8px 0 1em;
}

.header {
  position: fixed;
  width: 100%;
  background-color: $white;
  z-index: 1001;
  top: 0;
  box-shadow: 0 1px 0 $shadow-gray;

  @media print {
    display: none;
  }
}

.headerCol {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.headerLogo {
  width: 100%;
  max-width: 165px;
  min-height: 40px;
  margin: 14px 0;
  cursor: pointer;

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 125px;
  }
}

.navMenu {
  display: flex;
  list-style: none;
  margin: 0;
  align-items: stretch;
  height: 100%;

  @media screen and (max-width: $breakpoint-lg) {
    height: auto;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
}

.navBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  @media screen and (max-width: $breakpoint-lg) {
    @include transition-default(left, 500ms);
    position: fixed;
    width: 100%;
    height: auto;
    padding-bottom: 20px;
    background-color: $white;
    top: 60px;
    bottom: 0;
    z-index: 1010;
    left: -100%;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
  }
}

.phoneLink {
  color: $black;
  padding: 0 12px;
  cursor: pointer;
  text-align: center;
  position: relative;
  top: 2px;
  &:hover {
    color: $black;
    svg {
      color: $black;
      transform: scale(1.2);

      @media screen and (max-width: $breakpoint-lg) {
        color: $teal;
        transform: initial;
      }
    }
  }
  svg {
    font-size: 15px;
    padding-right: 4px;
    margin-bottom: 2px;
    transition: transform 300ms ease;
  }

  @media screen and (max-width: $breakpoint-lg) {
    color: $teal;
    width: 48px;
    height: 48px;
    padding: 16.5px;
    flex-direction: row-reverse;
    margin-top: 10px;
    svg {
      margin-bottom: 6px;
      padding-right: 0;

    }
  }
}


.searchButton {
  margin: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover .searchIcon {
    transform: scale(1.2);
  }

  @media screen and (max-width: $breakpoint-lg) {
    order: 0;
    margin: 4px 20px 0;
  }
}

svg.searchIcon {
  color: $black;
  transform: scale(1);
  transition: transform 300ms ease;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 14px;
  }
}

div.languageSelect {
  outline: none;
  margin-left: 2px;
  min-width: 62px;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
  & > div:first-of-type {
    border: none;
    outline-width: 0 !important;
    box-shadow: none;
    background-color: transparent;
  }
  :global(.valueContainer) {
    width: 25px;
    & > div:first-child {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: $black;
    }
  }
  :global(.indicator) {
    color: $black !important;
    & > * {
      color: $black !important;
    }
  }
  &:hover {
    :global(.valueContainer) {
      & > div:first-child {
        color: $black;
      }
    }
    :global(.indicator) {
      color: $black !important;
      & > * {
        color: $black !important;
      }
    }
  }
  svg {
    color: $black;
  }
}

:global(.active) + .navBar {
  left: 0;
}

.subNavBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: $breakpoint-lg) {
    align-items: flex-start;
    flex-direction: column;
  }
}

a.navLink {
  padding: 7px 20px;
  display: inline-flex;
  &:hover {
    color: $teal;
  }

  @media screen and (max-width: $breakpoint-lg) {
    padding: 16px 16px 16px 1px;
  }
}

.quoteIcon {
  @include transition-default(opacity);
  cursor: pointer;
  margin-left: 15px;
  &:hover {
    opacity: 0.7;
  }
}

.header.cover {
  background: linear-gradient(180deg, $white 0%, rgba($white, 0.5) 120%);
  box-shadow: 0 -30px 85px 70px rgba($white, 0.7);
  &.clear {
    background: transparent;
    padding-bottom: 0;
    box-shadow: none;
    .headerWrapper {
      background-color: $white;
      box-shadow: 0 1px 0 $shadow-gray;
    }
  }
}

.headerWrapper {
  position: relative;
    .row {
      display: grid;
      column-gap: 30px;
      grid-template-columns: 1fr 1fr;
      
      @media screen and (min-width: $min-breakpoint-lg) {
        grid-template-columns: 3.39fr 20fr;
      }    
  }
}

.bottomCol {
  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.copyLinks {
  @media screen and (max-width: $breakpoint-sm) {
    order: -1;
  }
}

.divider {
  width: 1px;
  background-color: $disabled;
  height: 15px;
  margin: auto 3px;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.headerIcon {
  margin: 0 12px;
  cursor: pointer;
  * {
    color: $black;

    @include transition-default(transform);
  }
  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
}

.headerIconLast {
  composes: headerIcon;

  @media screen and (max-width: $breakpoint-lg) {
    margin-right: 24px;
  }
}

.reevooColumn {
  & > button {
    margin-left: auto;
    @media screen and (max-width: $breakpoint-lg) {
      margin-left: 0;
    }
  }
}

.bottomBlock {
  margin-top: 26px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 15px;
  }
}

.menuRow {
  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 30px;
  }
}

.mobileLanguageWrapper {
  display: none;

  @media screen and (max-width: $breakpoint-lg) {
    display: flex;
    padding-top: 20px;
  }
}

.buttonLanguageMobile {
  font-size: 14px;
  padding: 13px 14px;
  width: 48px;
  height: 48px;
  & + & {
    margin-left: 10px;
  }
}

.screenOnly {
  @media print {
    display: none;
  }
}

.bannerWrapper {
  background-color: $pink;
  color: $white;
  text-align: center;
  position: relative;
  padding: 5px 35px;
  margin-bottom: -2px;

  &.schoolFinder {
    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    } 
  }
}

.bannerTitle {
  color: $white;
}

.bannerText {
  margin-bottom: 0;
  color: $white;
  font-size: 14px;
  a,
  a:link,
  a:visited {
    color: $white;
    text-decoration: underline;
  }
  p {
    margin-bottom: 0;
  }
}

.closeIcon {
  position: absolute;
  top: 4px;
  right: 0px;
  font-size: 20px;
  svg {
    color: $white;
  }
}

.withBanner {
  padding-top: 80px;

  @media screen and (max-width: $breakpoint-lg) {
    padding-top: 68px;
    &.schoolFinder {
      padding-top: 110px;
    }
  }
}

.activeLink {
  pointer-events: none;
}

.triggerWrapper {
  position: fixed;
  top: 50%;
  right: 6px;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 10;
  button {
    padding: 7px 5px 3px;
    font-size: 12px;
    line-height: 20px;
    width: auto;
    min-width: 34px;
    margin: 6px 0;
    box-shadow: 0 2px 4px rgba($dark, 0.1), 2px 4px 6px rgba($dark, 0.07);

    @media screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
    &:hover {
      opacity: 1;
    }
    & > div:first-child {
      width: 100%;
      & > span:first-child {
        margin: 0;
        min-width: 23px;
      }
    }
  }
}

.cartModal {
  :global(.ant-modal-content) {
    background-color: $gray-bg;

    @media screen and (max-width: $breakpoint-lg) {
      background-color: $white;
      height: 100%;
    }
  }

  :global(.ant-modal-header) {
    background-color: $gray-bg;
    border-color: transparent;
    padding: 25px 42px 0px;

    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  :global(.ant-modal-body) {
    padding: 0px 42px 20px;
  }

  :global(.ant-modal-close) {
    right: 22px;
    top: 13px;
    color: $black;

    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 100%;

    :global(.ant-modal-body) {
      padding: 0;
      height: 100%;

      main {
        height: 100%;
      }
    }
  }
}

.reisegarantie {
  margin-top: 15px;
}
