@import 'styles/variables';
@import 'styles/mixins';


.showMore {
  margin: 0 auto 40px;
  text-align: left;
  .button {
    padding: 28px 76px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
  }
}

.emptyText {
  padding-bottom: 40px;

  @media screen and (max-width: $breakpoint-lg) {
    padding-bottom: 20px;
  }
}

.wrapper {
  margin: 12px 0;

  .score {
    margin-right: 12px;
    font-size: 22px;
    line-height: 32px;
    vertical-align: bottom;
  
    @media screen and (max-width: $breakpoint-lg) {
      font-size: 20px;
      line-height: 22px;
      vertical-align: unset;
    }
  }

  .star {
    font-size: 24px;
    & > li:not(:last-child) {
      margin-right: 2px;
    }
  }
  
  .starColor {
    :global {
      li.ant-rate-star-full .ant-rate-star-second svg,
      li.ant-rate-star-half .ant-rate-star-first svg {
        color: #fbbc04;
      }
      li.ant-rate-star-zero .ant-rate-star-second svg,
      li.ant-rate-star-half .ant-rate-star-second svg {
        color: #dadce0;
      }
    }
  }

  .count {
    margin-left: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: $black;
      white-space: pre-line;
      @media screen and (max-width: $breakpoint-lg) {
        line-height: 20px;
      }
  }
  .schoolRatings {
    margin-top: 12px;
  }
}

.reviewCard {
  &:first-child {
    margin-top: 12px;
  }
  font-size: 16px;
  padding: 24px 0;
  border-top: 1px solid $gray-border;
  .link {
    color: $teal;
    font-weight: normal;
    text-decoration: underline;
    &:hover {
      color: $teal;
    }
  }

  .rating {
  margin-right: 8px;
  font-size: 16px;
  & > li:not(:last-child) {
    margin-right: 2px;
    }
  }
  .reviewText {
    padding-top: 12px;
    line-height: 1.5;
    margin: 0;
  }

  blockquote.answerText {
    margin: 12px auto 0 1%;
    width: 98%;
    padding: 0 0 0 12px;
    border-left: 2px solid $lightGray;
    font-size: 16px;
    & > p {
      &:first-child {
        font-weight: bold;
      }
      line-height: 1.5;
      margin: 0;
    }
  }
}

.toggleReview {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  margin-left: 4px;
  text-transform: lowercase;
  color: $black;
  @include transition-default(opacity color);
  &:hover {
    opacity: 0.8;
  }
  cursor: pointer;
}

.serviceBadge {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background-color: $black;
  color: $white;
  position: relative;
  cursor: pointer;
  max-width: 220px;
  padding: 8px;
   .percentage {
    width: 40%;
    margin-right: 8px;
    line-height: 1;
    display: inline-flex;
    & > span:first-child {
      font-size: 38px;
      font-weight: 600;
    }
    & > span:last-child {
      font-size: 13px;
      position: relative;
      top: 4px;
    }
  }
  .badgeCol {
    text-align: left;
    font-size: 13px;
    line-height: 1.15;
    & > p {
      margin-bottom: 0;
    }
    & > p + p {
      margin-top: 8px;
    } 
  }
}

.serviceModal {
  :global {
    .ant-modal-content {
      .ant-modal-header {
        padding-left: 42px;
        padding-right: 42px;
        border-bottom: none;
        @media screen and (max-width: $breakpoint-lg) {
          padding-left: 15px;
          padding-right: 15px;
        }

        .ant-modal-title {
          font-weight: bold;
          font-size: 22px;
          line-height: 32px;
          color: $black;
          letter-spacing: 0.02em;
        
          @media screen and (max-width: $breakpoint-lg) {
            font-size: 20px;
            line-height: 22px;
          }
        }
      }
      .ant-modal-body {
        padding-top: 0;
      }
    }
  }
  .wrapper {
    max-height: 60vh;
    overflow-y: auto;
  }

  .showMore {
    text-align: center;
    margin: 12px auto 0;
  }
}

.wrap {
  white-space: pre-wrap;
}
