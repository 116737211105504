@import "styles/variables";
@import "styles/mixins";

.title {
  margin-bottom: 8px;
}

.subTitle {
  margin-bottom: 20px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 8px;
  }
}

.schoolBody {
  padding: 20px 15px 20px;
  background-color: $white;
  border-left: none;
  flex: calc(100% - 271px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 10px 12px 16px;
    border-left: 1px solid $gray-450;
    border-top: none;
  }
}

.sortSelect {
  width: 154px;
  border: 1px solid $gray-border;
  & > div {
    height: 40px;
  }
  :global {
    .valueContainer > div:first-child {
      font-weight: normal;
    }
  }
}

.slider {
  :global {
    .card-slide {
      height: 271px;

      @media screen and (max-width: 1100px) {
        height: 320px;
      }

      @media screen and (max-width: $breakpoint-lg) {
        height: 172px;
      }
    }
  }
}

.schoolTitle {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media screen and (max-width: $breakpoint-xl) {
    font-size: 15px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 12px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 6px;
    font-size: 14px;
  }
}

.schoolSubTitle {
  font-weight: 400;
  margin-bottom: 16px;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 14px;
    line-height: 16px;
  }
}

.schoolCardCount {
  margin-bottom: 0;
}

.buttonWrapper {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 8px;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
  a {
    padding: 7px 20px;

    @media screen and (max-width: $breakpoint-xl) {
      font-size: 12px;
    }
  }
}

.raitingRow {
  padding: 8px 0;
  &:first-child {
    padding-top: 0;
  }
}

.raitingRowTitle {
  font-size: 13px;
  color: $black;
  width: 100px;
  white-space: nowrap;
}

.raitingProgress {
  width: 100%;
  padding: 0 12px;
  max-width: 124px;
  &.large {
    max-width: 240px;
  }
  :global {
    .ant-progress-inner,
    .ant-progress-bg,
    .ant-progress-success-bg {
      border-radius: 0;
    }
    .ant-progress-inner {
      height: 4px;
      background-color: $shadow-gray;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    padding: 0 8px 0 0;
    max-width: 148px;
  }
}

.raitingValue {
  font-size: 13px;
  line-height: 12px;
  color: $black;

  &.large {
  font-size: 16px;
  line-height: 32px;
    @media screen and (max-width: $breakpoint-sm) {
      font-size: 16px;
      line-height: 20px;   
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 12px;
    line-height: 12px;
  }
}

.raitingTable {
  width: 100%;
  margin-bottom: 9px;
  flex-wrap: nowrap;

  @media screen and (max-width: $breakpoint-lg) {
    flex-wrap: wrap;
  }
}

.marker {
  color: $pink;
  margin-right: 8px;
}

.divider {
  margin: 11px 0 12px;
}

.textRow {
  margin: 8px 0;
  display: flex;
  span:first-child {
    display: inline-block;
    min-width: 140px;
    margin-right: 20px;

    @media screen and (max-width: $breakpoint-lg) {
      min-width: 110px;
    }
  }
}

.cardWrapper {
  margin-bottom: 10px;
  border: 1px solid $gray-450;
  position: relative;

  @include transition-default(box-shadow);
  &:hover {
    border-color: $teal;
    box-shadow: 0 3px 8px rgba($black, 0.15);
  }
}

.raitingCol {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.raitingLabel {
  min-width: 150px;

  @media screen and (max-width: $breakpoint-xl) {
    min-width: 120px;
  }
  
  @media screen and (max-width: $breakpoint-lg) {
    width: 100%;
  }
  
  &.large {
    min-width: 200px;
    width: unset;
  
    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }
}

.mobileFooter {
  display: none;
  padding-top: 16px;

  @media screen and (max-width: $breakpoint-lg) {
    display: flex;
    justify-content: center;
  }
  a {
    width: 100%;
    max-width: 274px;
  }
}

.desktopOnly {
  display: flex;
  align-items: center;
  & > span {
    margin-right: 16px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.mobileHeading {
  display: none;

  @media screen and (max-width: $breakpoint-lg) {
    display: block;
    margin-bottom: 2px;
  }
}

.reviews {
  margin-top: 4px;
  font-size: 13px;
  line-height: 13px;

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 12px;
  }
}

.reevooBlock {
  max-width: 107px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  background-color: $pink-gray;
  padding: 11px 14px 9px 11px;
  align-items: flex-end;
  position: relative;

  @media screen and (max-width: $breakpoint-lg) {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: 8px;
  }
}

.reevooCol {
  @media screen and (max-width: $breakpoint-lg) {
    display: flex;
    align-items: flex-end;
  }
}

.sliderBlock {
  max-width: 271px;
  width: 100%;

  @media screen and (max-width: 1100px) {
    max-width: 200px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 100%;
  }
}

.noMargin {
  margin: 0;
}

.loadingWrapper {
  min-height: 300px;
  position: relative;
}

.loadingWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba($white, 0.5);
}
